import React from 'react';
import './ContactUs.css'; // Import your CSS file for styling
// import Map from './Map';


const ContactUs = () => {
  return (
    <div className="contact-container">
    <div className='contactFirstColumn'>
    <h1>Contact Us</h1>
      <div className="office">
        <h2>HEAD OFFICE</h2>
        <p>
          428, Vardhaman Market,
          Plot No. 75, Sector-17,
          Opp. Andhra Bank,
          Vashi, Navi Mumbai,
          Maharashtra. India.
          Pin - 400703.
        </p>
        <p>Telefax : +91- (022) 27882021 / 22</p>
        <p>Email: <a href="mailto:info@progressivegalaxy.com">info@progressivegalaxy.com</a></p>
      </div>
      <div className="office">
        <h2>GURGAON OFFICE</h2>
        <p>Address: [Gurgaon Address]</p>
        <p>Phone: [Phone Number]</p>
        <p>Email: <a href="mailto:info@progressivegalaxy.com">info@progressivegalaxy.com</a></p>
      </div>
      <div className="office">
        <h2>BARODA OFFICE</h2>
        <p>Address: [Baroda Address]</p>
        <p>Phone: [Phone Number]</p>
        <p>Email: <a href="mailto:info@progressivegalaxy.com">info@progressivegalaxy.com</a></p>
      </div>
      <div className="queries">
        <h2>For any Marketing related queries:</h2>
        <p>Email: <a href="mailto:marketing@progressivegalaxy.com">marketing@progressivegalaxy.com</a></p>
      </div>
      <div className="queries">
        <h2>For any Purchase related queries:</h2>
        <p>Email: <a href="mailto:purchase@progressivegalaxy.com">purchase@progressivegalaxy.com</a></p>
      </div>
      <div className="queries">
        <h2>For any Recruitment related queries:</h2>
        <p>Email: <a href="mailto:hr@progressivegalaxy.com">hr@progressivegalaxy.com</a></p>
      </div>
    </div>

      <div className='contactSecondColumn'>

{/* add map here */}

      </div>
    </div>
  );
}

export default ContactUs;
